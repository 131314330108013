var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbItems}}),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clients,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"item.address",fn:function(ref){
var item = ref.item;
return [(item.sockets.length)?_c('span',[_vm._v(_vm._s(item.sockets[0].handshake.address))]):_vm._e()]}},{key:"item.transport",fn:function(ref){
var item = ref.item;
return [(item.sockets.length)?_c('Transport',{attrs:{"transport":item.sockets[0].transport}}):_vm._e()]}},{key:"item.sockets",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sockets.length)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isSocketDisconnectSupported)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"disabled":_vm.isReadonly,"small":""},on:{"click":function($event){return _vm.disconnect(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("clients.disconnect")))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"small":""},on:{"click":function($event){return _vm.displayDetails(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("clients.displayDetails")))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }