var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fill-height"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("details")))]),_c('v-card-text',[_c('h4',[_vm._v(_vm._s(_vm.$t("sockets.client")))])]),_c('v-simple-table',{attrs:{"dense":""}},[[_c('tbody',[_c('tr',[_c('td',{staticClass:"key-column"},[_vm._v(_vm._s(_vm.$t("id")))]),_c('td',[_vm._v(" "+_vm._s(_vm.client.id)+" ")]),_c('td',{attrs:{"align":"right"}},[(_vm.client.connected)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"small":""},on:{"click":function($event){return _vm.navigateToClient()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,false,898213143)},[_c('span',[_vm._v(_vm._s(_vm.$t("clients.displayDetails")))])]):_vm._e()],1)]),_c('tr',[_c('td',{staticClass:"key-column"},[_vm._v(_vm._s(_vm.$t("status")))]),_c('td',[_c('ConnectionStatus',{attrs:{"connected":_vm.client.connected}})],1),_c('td',{attrs:{"align":"right"}},[(_vm.isSocketDisconnectSupported && _vm.client.connected)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.isReadonly,"small":""},on:{"click":function($event){return _vm.disconnectClient()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout")])],1)]}}],null,false,1004975867)},[_c('span',[_vm._v(_vm._s(_vm.$t("clients.disconnect")))])]):_vm._e()],1)]),_c('tr',[_c('td',{staticClass:"key-column"},[_vm._v(_vm._s(_vm.$t("sockets.transport")))]),_c('td',[_c('Transport',{attrs:{"transport":_vm.socket.transport}})],1),_c('td')]),_c('tr',[_c('td',{staticClass:"key-column"},[_vm._v(_vm._s(_vm.$t("sockets.address")))]),_c('td',[_vm._v(_vm._s(_vm.socket.handshake.address))]),_c('td')])])]],2),_c('v-card-text',[_c('h4',[_vm._v(_vm._s(_vm.$t("sockets.socket")))])]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"key-column"},[_vm._v(_vm._s(_vm.$t("namespace")))]),_c('td',[_c('code',[_vm._v(_vm._s(_vm.socket.nsp))])]),_c('td')]),_c('tr',[_c('td',{staticClass:"key-column"},[_vm._v(_vm._s(_vm.$t("id")))]),_c('td',[_vm._v(_vm._s(_vm.socket.id))]),_c('td')]),_c('tr',[_c('td',{staticClass:"key-column"},[_vm._v(_vm._s(_vm.$t("status")))]),_c('td',[_c('ConnectionStatus',{attrs:{"connected":_vm.socket.connected}})],1),_c('td',{attrs:{"align":"right"}},[(_vm.isSocketDisconnectSupported && _vm.socket.connected)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"disabled":_vm.isReadonly,"small":""},on:{"click":function($event){return _vm.disconnectSocket()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout")])],1)]}}],null,false,2351110648)},[_c('span',[_vm._v(_vm._s(_vm.$t("sockets.disconnect")))])]):_vm._e()],1)]),_c('tr',[_c('td',{staticClass:"key-column"},[_vm._v(_vm._s(_vm.$t("sockets.creation-date")))]),_c('td',[_vm._v(_vm._s(_vm.creationDate))]),_c('td')])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }